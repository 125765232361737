import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  BaseTablePage,
  ButtonColor,
  DataTypeEnum,
  IAction,
  Issue,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  RecordStatusEnumFilterWithoutDeleted,
  UpdateItem,
  getEnumOptions,
  makePlural,
  routeToLocaleCase,
  toCamelCase,
  toKebabCase,
} from '@shared/classes';
import { BaseFormPopupComponent } from '@shared/components/misc/base-form-popup/base-form-popup.component';
import { UserSelectorComponent } from '@shared/components/selectors/user-selector/user-selector.component';
import { AppDialogService } from '@shared/services/app-dialog.service';
import { ExportDataService } from '@shared/services/export-data.service';
import { LoaderService } from '@shared/services/loader.service';
import { groupBy, sortBy } from 'lodash-es';
import { TreeDragDropService, TreeNode } from 'primeng/api';
import { IssuesDataService } from '../../services/data/issues-data.service';
import { IssuesItemFormComponent } from '../issues-item-form/issues-item-form.component';

@Component({
  selector: 'app-issues-tree-list',
  templateUrl: './issues-tree-list.component.html',
  styleUrls: ['./issues-tree-list.component.scss'],
})
export class IssuesTreeListComponent extends BaseTablePage<Issue> implements OnInit {
  nodes: TreeNode[];
  selectedFile: TreeNode | TreeNode[] = [];
  groupedNodes: any;
  formattedNodes: TreeNode[];
  visited: any = {};
  listOfStatuses = getEnumOptions(Issue.RecordStatusEnum);
  statusFilter: FormControl = new FormControl(null);
  addIssueAction: IAction = {
    id: 1,
    label: 'Add Issue',
    buttonType: 'button',
    command: this.onOpenAddDialog.bind(this),
    icon: 'pi pi-plus',
    color: ButtonColor.Primary,
    iconPos: 'left',
  };
  expandAllAction: IAction = {
    id: 2,
    label: 'Expand All',
    buttonType: 'button',
    command: this.expandAll.bind(this),
    icon: 'pi pi-chevron-down',
    color: ButtonColor.Primary,
    iconPos: 'left',
    buttonStyle: 'outlined',
  };
  collapseAllAction: IAction = {
    id: 3,
    label: 'Collapse All',
    buttonType: 'button',
    command: this.collapseAll.bind(this),
    icon: 'pi pi-chevron-up',
    color: ButtonColor.Primary,
    iconPos: 'left',
    buttonStyle: 'outlined',
  };
  editNodeAction: IAction = {
    id: 4,
    label: '',
    buttonStyle: 'text',
    buttonType: 'button',
    command: this.onRowEdit.bind(this),
    icon: 'pi pi-pencil',
    color: ButtonColor.Primary,
    iconPos: 'left',
  };
  saveEditNodeAction: IAction = {
    id: 5,
    label: '',
    buttonStyle: 'text',
    buttonType: 'button',
    command: this.onRowEditSave.bind(this),
    icon: 'pi pi-check',
    color: ButtonColor.Success,
    iconPos: 'left',
  };
  cancelEditNodeAction: IAction = {
    id: 6,
    label: '',
    buttonStyle: 'text',
    buttonType: 'button',
    command: this.onRowEditCancel.bind(this),
    icon: 'pi pi-times',
    color: ButtonColor.Danger,
    iconPos: 'left',
  };
  addNodeAction: IAction = {
    id: 7,
    label: '',
    buttonStyle: 'text',
    buttonType: 'button',
    command: this.onRowAddChild.bind(this),
    icon: 'pi pi-plus',
    color: ButtonColor.Primary,
    iconPos: 'left',
  };
  expandedState = false;
  constructor(
    private requestService: IssuesDataService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router,
    private route: ActivatedRoute,
    public loaderService: LoaderService,
    public dragService: TreeDragDropService,
    public localeService: LocaleService
  ) {
    super(
      requestService,
      exportService,
      appDialogService,
      router,
      {
        moduleKeyword: ModuleKeywords.Issue,
        routePrefix: ModuleRoutePrefix.IssueManagement,
      },
      localeService
    );
    this.subs.sink = this.route.params.subscribe({
      next: (params) => {
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
                ),
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
                ),
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
            ],
          },
        });
      },
    });
    this.subs.sink = this.statusFilter.valueChanges.subscribe((res) => {
      this.loadData(res);
    });
  }

  ngOnInit(): void {
    this.nodes = [];
    this.loadData();
  }

  loadData(status = null) {
    let citationProjectionFields = [
      'id',
      'code',
      'recordStatus',
      'order',
      'name',
      'description',
      'assignee',
      'criticality',
      'issueOwner',
      'parent',
      'issueTypeCode',
      'sourceType',
      'sourceCode',
    ];
    let citationFilters: any[] = [...this.tableEvent.filters];
    if (status) citationFilters.push({ property: 'recordStatus', operation: 'EQUAL', value: status });
    this.subs.sink = this.requestService
      .search<Issue>(
        { ...this.tableEvent.pageInfo.pagination },
        { projectionFields: citationProjectionFields, filters: citationFilters },
        { showLoading: true, showMsg: false, params: { all: true } }
      )
      .subscribe({
        next: (res: any) => {
          this.tableData = res;
          this.tableData = sortBy(this.tableData, ['order']);
          this.groupedNodes = groupBy(this.tableData, 'parent');
          this.visited = {};
          this.formatDataForTreeViewRecursive(this.tableData);
        },
        error: (error) => {},
        complete: () => {},
      });
    // if (this.authDoc) {
    // } else {
    //     this.subs.sink = this.authorityRequestService.getByIdOrCode<AuthorityDocument>(this.authID).subscribe({
    //         next: (res) => {
    //             this.authDoc = res.data;
    //             let citationFilters: any[] = [...this.tableEvent.filters, { "property": "authorityDocument", "operation": "EQUAL", "value": this.authDoc.id }]
    //             if (status) citationFilters.push({ "property": "status", "operation": "EQUAL", "value": status })
    //             this.subs.sink = this.requestService
    //                 .search<Issue>(
    //                     { ...this.tableEvent.pageInfo.pagination },
    //                     { projectionFields: citationProjectionFields, filters: citationFilters },
    //                     { showLoading: true, showMsg: false, params: { all: true } }
    //                 )
    //                 .subscribe({
    //                     next: (res: any) => {

    //                         this.tableData = res;
    //                         this.groupedNodes = groupBy(this.tableData, 'parent.id');
    //                         this.visited = {};
    //                         this.formatDataForTreeViewRecursive(this.tableData)
    //                     },
    //                     error: (error) => {

    //                     },
    //                     complete: () => { },
    //                 });
    //         }
    //     })
    // }
  }
  setCols() {
    this.cols = [
      {
        name: 'Id',
        key: 'code',
        dataType: DataTypeEnum.CodeWithStatus,
        passRowAsData: true,
        filter: {
          type: 'text',
          matchMode: 'startsWith',
        },
        // frozen: true,
        alignFrozen: 'left',
      },
      {
        name: 'Record Status',
        key: 'recordStatus',
        dataType: DataTypeEnum.Badge,
        filter: {
          type: 'enum',
          display: 'menu',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          enumClass: RecordStatusEnumFilterWithoutDeleted,
        },
      },
      {
        name: 'Name',
        key: 'name',
        dataType: DataTypeEnum.Text,
        filter: {
          type: 'text',
          display: 'menu',
          matchMode: 'startsWith',
          showMatchModes: true,
          showAddButton: true,
          showOperator: true,
        },
      },
      {
        name: 'Creator Name',
        key: 'creatorName',
        dataType: DataTypeEnum.UserListView,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      {
        name: 'Creation Date',
        key: 'creationDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
      {
        name: 'Last Modifier',
        key: 'lastModifierName',
        dataType: DataTypeEnum.UserListView,
        filter: {
          type: 'multiDropdown',
          matchMode: 'in',
          showMatchModes: false,
          showAddButton: false,
          showOperator: false,
          dynamicInput: {
            componentType: UserSelectorComponent,
            options: {
              label: '',
              name: '',
              control: new FormControl(null),
            },
          },
        },
      },
      {
        name: 'Last Modification Date',
        key: 'lastModificationDate',
        dataType: DataTypeEnum.DateLong,
        filter: {
          type: 'date',
          matchMode: 'dateBefore',
        },
      },
    ];
  }
  addWithParent(row: Issue) {
    this.router.navigateByUrl(`${this.routePrefix}${toKebabCase(this.moduleKeyword)}/create/${(row as any).id}`);
  }
  editItem(row: Issue): any {
    this.router.navigateByUrl(
      `${this.routePrefix}${toKebabCase(this.moduleKeyword)}/edit/${(row as any).code || (row as any).id}`
    );
  }
  viewItem(row: Issue): any {
    this.router.navigateByUrl(
      `${this.routePrefix}${toKebabCase(this.moduleKeyword)}/view/${(row as any).code || (row as any).id}`
    );
  }
  addNewItem(): any {
    this.router.navigateByUrl(`${this.routePrefix}${toKebabCase(this.moduleKeyword)}/create`);
  }

  formatDataForTreeViewRecursive(data: Issue[]) {
    this.formattedNodes = [];
    this.groupedNodes['null']?.forEach((el, index) => {
      if (!!!this.visited[el.code]) {
        this.visited[el.code] = true;
        this.formattedNodes.push(this.getFormattedChildren(el.code, el));
      }
    });
  }
  getFormattedChildren(code, data): TreeNode {
    let node = {
      label: data.name,
      data: { ...data, editMode: 'view' },
      //   expandedIcon: "pi pi-folder-open",
      //   collapsedIcon: "pi pi-folder",
      //   icon: 'pi pi-file',
      children: [],
      expanded: false,
      selectable: false,
      leaf: false,
    };
    if (!!this.groupedNodes[code]) {
      this.groupedNodes[code].forEach((el, index) => {
        if (!!!this.visited[el.code]) {
          this.visited[el.code] = true;
          let ret = this.getFormattedChildren(el.code, el);
          node.children.push(ret);
        } else {
        }
      });
    } else {
      return node;
    }
    return node;
  }

  expandAll() {
    this.formattedNodes.forEach((node, index) => {
      setTimeout(() => {
        this.expandRecursive(node, true);
      }, 5);
    });
    this.expandedState = true;
  }

  collapseAll() {
    this.formattedNodes.forEach((node, index) => {
      setTimeout(() => {
        this.expandRecursive(node, false);
      }, 5);
    });
    this.expandedState = false;
  }

  private expandRecursive(node: TreeNode, isExpand: boolean) {
    node.expanded = isExpand;
    if (node.children) {
      node.children.forEach((childNode) => {
        this.expandRecursive(childNode, isExpand);
      });
    }
  }
  rowCopy;
  onRowEdit(rowData: TreeNode) {
    this.rowCopy = rowData;
    let node = this.findNodeByCode(this.formattedNodes, rowData.data.code);
    node.data.editMode = 'edit';
    // Do something with the edited row data
  }
  onRowAddChild(rowData: TreeNode) {
    this.rowCopy = rowData;
    let node = this.findNodeByCode(this.formattedNodes, rowData.data.code);
    node.data.editMode = 'create';
    // Do something with the edited row data
  }
  onRowEditSave(data, code) {
    let node = this.findNodeByCode(this.formattedNodes, code);
    if (node?.data?.editMode == 'edit') {
      this.subs.sink = this.requestService.patchSingleUpdate(data, node?.data?.code).subscribe((res) => {
        node.data.editMode = 'view';
        (data as Array<UpdateItem>).forEach((element) => {
          node.data[element.key] = element.value;
        });
      });
    } else {
      this.subs.sink = this.requestService.create(data).subscribe((res) => {
        let childData = (res.data as any).body;
        // (data.createItems as Array<UpdateItem>).forEach(element => {
        //     childData[element.key] = element.value;
        // });
        let childNode = {
          label: data.name,
          data: { ...childData, editMode: 'view' },
          //   expandedIcon: "pi pi-folder-open",
          //   collapsedIcon: "pi pi-folder",
          //   icon: 'pi pi-file',
          children: [],
          expanded: false,
          selectable: false,
          leaf: false,
        };
        node?.children?.push(childNode);
        node.data.editMode = 'view';
      });
    }
    // node = {...data,data:{...data.data,editMode:false}};
  }
  onRowEditCancel(data, code) {
    let node = this.findNodeByCode(this.formattedNodes, code);
    node.data.editMode = 'view';
  }
  onDrop(event) {
    let node = this.getParentNode(this.formattedNodes, event.dragNode?.data?.code);

    this.subs.sink = this.requestService
      .patchSingleUpdate(
        [
          { key: 'order', value: ((event.index + event.index - 1) / 2) as any },
          { key: 'parent', value: node?.data?.code },
        ],
        event.dragNode?.data?.code
      )
      .subscribe((res) => {});
  }
  findNodeByCode(node: TreeNode[], searchCode: string): TreeNode {
    for (let i = 0; i < node.length; i++) {
      if (node[i].data && node[i].data.code === searchCode) {
        return node[i];
      }
      if (node[i].children) {
        const result = this.findNodeByCode(node[i].children, searchCode);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }
  getParentNode(nodes: TreeNode[], code: string, parent: TreeNode = null): TreeNode {
    for (let node of nodes) {
      if (node.children) {
        // Recursively check child nodes
        let parentNode = this.getParentNode(node.children, code, node);
        if (parentNode) {
          return parentNode;
        }
      }
      // Check if node's code matches input code
      if (node.data?.code === code) {
        return parent;
      }
    }
    // No match found
    return null;
  }
  onOpenAddDialog() {
    this.appDialogService.showDialog(
      BaseFormPopupComponent,
      'Add Issue',
      (data) => {
        if (data) {
          let resObj: any = {};
          data.forEach((element) => {
            resObj[element.key] = element.value;
          });
          // this.formGroup.patchValue({ ...resObj });
          this.subs.sink = this.requestService.create({ createItems: data }).subscribe((res) => {
            let childData: any = { code: (res?.data as any)?.code };
            (data as Array<UpdateItem>).forEach((element) => {
              childData[element.key] = element.value;
            });
            let childNode = {
              label: data.name,
              data: { ...childData, editMode: 'view' },
              //   expandedIcon: "pi pi-folder-open",
              //   collapsedIcon: "pi pi-folder",
              //   icon: 'pi pi-file',
              children: [],
              expanded: false,
              selectable: false,
              leaf: false,
            };
            if (resObj?.parent) {
              let node = this.findNodeByCode(this.formattedNodes, resObj?.parent);
              if (node) {
                node?.children?.push(childNode);
                node.data.editMode = 'view';
              }
            } else {
              this.formattedNodes.unshift(childNode);
            }
          });
        }
      },
      {
        data: {
          dynamicViewComponent: IssuesItemFormComponent,
          dataService: this.requestService,
          filters: [],
          selectedRows: [],
          patchData: false,
          formData: {},
        },
      }
    );
  }
}
namespace IssueStatusFilter {
  export type RecordStatusEnum = 'LOCKED' | 'ACTIVE' | 'IN_ACTIVE';
  export const RecordStatusEnum = {
    Locked: 'LOCKED' as RecordStatusEnum,
    Active: 'ACTIVE' as RecordStatusEnum,
    InActive: 'IN_ACTIVE' as RecordStatusEnum,
  };
}
